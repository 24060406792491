
import { Options, Vue, prop } from "vue-class-component";
import { OptionsClass } from "@/domain/entities/MainApp";

class Prop {
  modelValue = prop<any>({
    required: true
  });
}

@Options({
  emits: ["change"]
})
export default class AdvanceFilter extends Vue.with(Prop) {
  filterData: any = {
    sttType: new OptionsClass(),
    paymentMethod: new OptionsClass()
  };
  isChevron = false;
  openFilter = false;

  resetFilter() {
    this.filterData = {
      sttType: "",
      paymentMethod: ""
    };
    this.onChange();
  }

  get sumFilter() {
    return (
      ((this.filterData.sttType as OptionsClass)?.value ? 1 : 0) +
      ((this.filterData.paymentMethod as OptionsClass)?.value ? 1 : 0)
    );
  }

  // customDropdown
  customDropdownOpened: any = {
    sttType: false,
    paymentMethod: false
  };
  get customDropdownOptions() {
    return {
      sttType: [
        new OptionsClass({
          value: "",
          name: "Semua"
        }),
        new OptionsClass({
          value: "cod",
          name: "COD"
        }),
        new OptionsClass({
          value: "non-cod",
          name: "Non COD"
        })
      ],
      paymentMethod: [
        new OptionsClass({
          value: "",
          name: "Semua"
        }),
        new OptionsClass({
          value: "cash",
          name: "Cash"
        }),
        new OptionsClass({
          value: "qris",
          name: "QRIS"
        }),
        new OptionsClass({
          value: "free",
          name: "GRATIS"
        })
      ]
    };
  }
  onSelectCustomDropdown(data: OptionsClass, key: string) {
    if (key === "sttType") {
      this.filterData.paymentMethod = new OptionsClass();
    }
    if ((this.filterData[key] as OptionsClass)?.value === data.value) {
      this.filterData[key] = new OptionsClass();
    } else {
      this.filterData[key] = data;
    }
    this.customDropdownOpened[key] = false;
    this.isChevron = true;
    this.onChange();
  }
  onChange() {
    this.$emit("change", this.filterData);
  }
}
