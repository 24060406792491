import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full" }
const _hoisted_2 = { class: "flex z-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_Header = _resolveComponent("Header")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { class: "items-center" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_Search, {
            class: "w-64 self-center",
            onInput: _ctx.onSearch,
            onClear: _ctx.clearSearch,
            value: _ctx.searchValue,
            placeholder: "Cari No. STT"
          }, null, 8, ["onInput", "onClear", "value"]),
          _createVNode(_component_time_range_picker, {
            startDate: _ctx.startDate,
            endDate: _ctx.endDate,
            onSelect: _ctx.setDateRange,
            default: "hari ini"
          }, null, 8, ["startDate", "endDate", "onSelect"])
        ])
      ]),
      _: 1
    }),
    (_ctx.isError && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          errorType: _ctx.errorCause,
          onTryAgain: _ctx.fetchPodDexList
        }, null, 8, ["errorType", "onTryAgain"]))
      : (
        _ctx.podDexList.length === 0 &&
          !_ctx.searchValue &&
          !_ctx.startDate &&
          !_ctx.endDate &&
          !_ctx.isLoading &&
          _ctx.isFirstRequest
      )
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 1,
            data: "POD/DEX"
          }))
        : (_openBlock(), _createBlock(_component_TableV2, {
            key: 2,
            columns: _ctx.columns,
            data: _ctx.podDexList,
            loading: _ctx.isLoading,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
            totalColumnFreeze: 0,
            isRightFreeze: true,
            pinnedSubstractHeight: "290px",
            onRequest: _ctx.fetchPodDexList,
            errorCause: _ctx.errorCause
          }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "errorCause"]))
  ]))
}