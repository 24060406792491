
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {}
})
export default class Delivery extends Vue {

  mounted() {
    PodDexController.initDataTable();
  }

  unmounted() {
    PodDexController.onReset();
  }
  // Tabs
  tabs = [
    { name: "del/dex", title: "DEL/DEX" },
    { name: "codrej", title: "CODREJ" },
    { name: "pod", title: "POD" }
  ];

  changeTabs(value: string) {
    this.$router.push({
      name: value
    });
    PodDexController.initDataTable();
    PodDexController.setType(value === "del/dex" ? "DEL-DEX" : value.toUpperCase());
    PodDexController.onReset();
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }
}
