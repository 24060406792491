import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "\n      bg-white\n      flex flex-col\n      text-12px\n      xxl:text-14px\n      overflow-hidden\n      h-screen\n      relative\n      w-full\n    " }
const _hoisted_2 = { class: "flex z-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_AdvanceFilter = _resolveComponent("AdvanceFilter")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_Header = _resolveComponent("Header")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_WidgetDownload = _resolveComponent("WidgetDownload")!
  const _component_ModalDownload = _resolveComponent("ModalDownload")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Header, { class: "items-center" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_Search, {
              class: "w-64 self-center",
              onInput: _ctx.onSearch,
              onClear: _ctx.clearSearch,
              value: _ctx.searchValue,
              placeholder: "Cari No. STT"
            }, null, 8, ["onInput", "onClear", "value"]),
            _createVNode(_component_time_range_picker, {
              startDate: _ctx.startDate,
              endDate: _ctx.endDate,
              onSelect: _ctx.setDateRange,
              default: "hari ini",
              class: "mr-4"
            }, null, 8, ["startDate", "endDate", "onSelect"]),
            _createVNode(_component_AdvanceFilter, {
              onChange: _ctx.onChangeAdvancedFilter,
              class: "mr-4"
            }, null, 8, ["onChange"]),
            _createVNode(_component_LpButton, {
              textColor: "white",
              iconLeft: "document-download-outline-white",
              title: "Download Data",
              onClick: _cache[1] || (_cache[1] = () => (_ctx.isDownload = true))
            })
          ])
        ]),
        _: 1
      }),
      (_ctx.isError && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_ErrorMessage, {
            key: 0,
            errorType: _ctx.errorCause,
            onTryAgain: _ctx.fetchPodDexList
          }, null, 8, ["errorType", "onTryAgain"]))
        : (
        _ctx.podDexList.length === 0 &&
          !_ctx.searchValue &&
          !_ctx.startDate &&
          !_ctx.endDate &&
          !_ctx.isLoading &&
          _ctx.isFirstRequest
      )
          ? (_openBlock(), _createBlock(_component_EmptyState, {
              key: 1,
              data: "POD/DEX"
            }))
          : (_openBlock(), _createBlock(_component_TableV2, {
              key: 2,
              columns: _ctx.columns,
              data: _ctx.podDexList,
              loading: _ctx.isLoading,
              pagination: _ctx.pagination,
              "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => (_ctx.pagination = $event)),
              pinnedSubstractHeight: "290px",
              onRequest: _ctx.fetchPodDexList,
              errorCause: _ctx.errorCause,
              isRightFreeze: true,
              customButtonAction: _ctx.customButton,
              totalColumnFreeze: 1,
              paginationWidth: "w-3/4"
            }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "errorCause", "customButtonAction"]))
    ]),
    _createVNode(_component_WidgetDownload),
    _createVNode(_component_ModalDownload, {
      modelValue: _ctx.isDownload,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDownload = $event))
    }, null, 8, ["modelValue"])
  ], 64))
}