import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "\n      bg-white\n      flex flex-col\n      text-12px xxl:text-14px\n      overflow-hidden\n      h-screen\n      relative\n      w-full\n    " }
const _hoisted_2 = { class: "flex z-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Header = _resolveComponent("Header")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_ModalForm = _resolveComponent("ModalForm")!
  const _component_Notification = _resolveComponent("Notification")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Header, { class: "items-center" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_Search, {
              class: "w-64 self-center",
              onInput: _ctx.onSearch,
              onClear: _ctx.clearSearch,
              value: _ctx.searchValue,
              placeholder: "Cari No. STT"
            }, null, 8, ["onInput", "onClear", "value"]),
            _createVNode(_component_time_range_picker, {
              startDate: _ctx.startDate,
              endDate: _ctx.endDate,
              onSelect: _ctx.setDateRange,
              default: "hari ini"
            }, null, 8, ["startDate", "endDate", "onSelect"]),
            _createVNode(_component_Select, {
              dataSelect: _ctx.statusData,
              onOpenSelect: _ctx.onOpenStatusSelect,
              onCloseSelect: _ctx.onCloseStatusSelect,
              onChooseSelect: _ctx.onSelectStatus,
              value: _ctx.statusName,
              isOpenMenu: _ctx.statusSelect,
              isDefaultBlack: true,
              class: 'w-48 ml-4'
            }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu"])
          ])
        ]),
        _: 1
      }),
      (_ctx.isError && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_ErrorMessage, {
            key: 0,
            errorType: _ctx.errorCause,
            onTryAgain: _ctx.fetchPodDexList
          }, null, 8, ["errorType", "onTryAgain"]))
        : (
        _ctx.podDexList.length === 0 &&
          !_ctx.searchValue &&
          !_ctx.startDate &&
          !_ctx.endDate &&
          !_ctx.isLoading &&
          _ctx.isFirstRequest
      )
          ? (_openBlock(), _createBlock(_component_EmptyState, {
              key: 1,
              data: "POD/DEX"
            }))
          : (_openBlock(), _createBlock(_component_TableV2, {
              key: 2,
              columns: _ctx.columns,
              data: _ctx.podDexList,
              loading: _ctx.isLoading,
              pagination: _ctx.pagination,
              "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
              totalColumnFreeze: 1,
              isRightFreeze: true,
              pinnedSubstractHeight: "290px",
              onRequest: _ctx.fetchPodDexList,
              errorCause: _ctx.errorCause,
              customButtonAction: _ctx.customButton,
              totalPercentFreeze: 19
            }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "errorCause", "customButtonAction"]))
    ]),
    _createVNode(_component_ModalForm, {
      modelValue: _ctx.isUpdate,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isUpdate = $event)),
      onClose: () => _ctx.setUpdate(_ctx.detailData, false)
    }, null, 8, ["modelValue", "onClose"]),
    _createVNode(_component_Notification, {
      modelValue: _ctx.isUpdateNotification,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isUpdateNotification = $event)),
      onRefresh: _ctx.fetchPodDexList,
      timeout: _ctx.podDexList.length ? 600 : 0
    }, null, 8, ["modelValue", "onRefresh", "timeout"]),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: _ctx.onClosePaidStatus,
      title: "Pastikan Nomor STT Sudah Terbayar",
      message: "Cek kembali nomor STT Adjusted atau yang kelebihan berat, pastikan sudah terbayar lunas",
      image: "payment/payment-cash-illustration",
      textSuccess: "OK",
      class: "px-8"
    }, null, 8, ["onSubmit"]), [
      [_vShow, !_ctx.isPaid]
    ])
  ], 64))
}