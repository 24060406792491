
import { Vue, Options } from "vue-class-component";
import {
  convertDecimalWithComma,
  ellipsisString,
  formatDateWithoutTime,
  formatPriceRP,
  formatTimeCustom,
  isMigrateFromElexys
} from "@/app/infrastructures/misc/Utils";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import { Pagination } from "@/domain/entities/Pagination";
import AdvanceFilter from "../components/advance-filter.vue";
import { PodDexData } from "@/domain/entities/PodDex";
import ModalDownload from "../components/modal-download.vue";

@Options({
  components: {
    EmptyState,
    AdvanceFilter,
    ModalDownload
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    }
  }
})
export default class StationTransitInDest extends Vue {
  mounted() {
    this.refs = this.$refs;
    PodDexController.setType("POD");
  }
  unmounted() {
    PodDexController.onReset();
  }

  refs: any = "";

  updateNotificationModel = true;

  fetchPodDexList() {
    PodDexController.fetchPodDexList();
  }

  get isFirstRequest() {
    return PodDexController.isFirstRequest;
  }

  // loading
  get isLoading() {
    return PodDexController.isLoading;
  }

  // filter date
  get startDate() {
    return PodDexController.filter.startDate;
  }

  get endDate() {
    return PodDexController.filter.endDate;
  }

  setDateRange(value: Array<any>) {
    PodDexController.setStartDate(value[0]);
    PodDexController.setEndDate(value[1]);
    value[0] && value[1] && this.fetchPodDexList();
  }

  // filter search
  get searchValue() {
    return PodDexController.filter.search;
  }

  onSearch(value: string) {
    PodDexController.setSearch(value);
    PodDexController.setPagination(new Pagination(1, 10));
    this.fetchPodDexList();
  }

  clearSearch() {
    PodDexController.setSearch("");
    this.fetchPodDexList();
  }
  onChangeAdvancedFilter(filter: any) {
    PodDexController.filter.sttType = filter.sttType;
    PodDexController.filter.paymentMethod = filter.paymentMethod;
    this.fetchPodDexList();
  }

  // Table
  get columns() {
    const columns = [
      {
        name: "No.",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: PodDexData, index: number) => {
          return `
            ${
              item.sttIsCod || item.sttIsDfod
                ? `
                <div class='absolute' style='margin-top: -30px; margin-left: -15px;'>
                  ${
                    item.sttIsDfod
                      ? `<img src='${require("@/app/ui/assets/svg/dfod.svg")}' alt="boss-float" />`
                      : `<img src='${require("@/app/ui/assets/svg/cod-label.svg")}' alt="cod-float" />`
                  }
                </div>`
                : ""
            }
            <div class="overflow-ellipsis text-left">${index + 1}</div>
          `;
        }
      },
      {
        name: `No. STT`,
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: PodDexData) => {
          return `<div class="overflow-ellipsis text-left">${item.sttNo}</div>`;
        }
      },
      {
        name: `No. Referensi`,
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: PodDexData) => {
          return `<div class="overflow-ellipsis text-left">${item.sttNoRefExternal ||
            "-"}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: PodDexData) => {
          return `<div class="overflow-ellipsis text-left">${item.totalPiece}</div>`;
        }
      },
      {
        name: "Berat Dikenakan Biaya",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: PodDexData) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.chargeableWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Produk",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: PodDexData) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.productName}
                  </div>
                </div>`;
        }
      },
      {
        name: "Status Terakhir",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: PodDexData) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                    ${item.lastStatus}
                  </div>
                </div>`;
        }
      },
      {
        name: "Komoditas",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: PodDexData) => {
          return `<div class="overflow-ellipsis text-left">${item.commodityName}</div>`;
        }
      },
      {
        name: "Biaya COD",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: PodDexData) => {
          return `<div class="overflow-ellipsis text-left">${formatPriceRP(
            item.codAmount
          )}</div>`;
        }
      },
      {
        name: "Tipe Pembayaran COD",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: PodDexData) => {
          return `<div class="overflow-ellipsis text-left">${item.paymentMethod}</div>`;
        }
      },
      {
        name: "Tanggal DEL",
        styleHead: "w-50 text-left",
        render: (item: PodDexData) => {
          return `<div class="text-black-lp-300">${formatDateWithoutTime(
            item.deliveryDate
          )}</div>
            <div class="capitalize text-gray-lp-500">${formatTimeCustom({
              date: item.deliveryDate,
              isSecond: false
            })}</div>`;
        }
      },
      {
        name: "Nama Kurir",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: PodDexData) => {
          return `<div class="overflow-ellipsis text-left">${item.courierName}</div>`;
        }
      },
      {
        name: "No. Telp Kurir",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: PodDexData) => {
          return `<div class="overflow-ellipsis text-left">${item.courierPhoneNumber}</div>`;
        }
      },
      {
        name: "Dikirim Oleh",
        styleHead: "w-50 text-left",
        vtooltip: (item: any) =>
          item.deliveredBy?.length > 34 ? item.deliveredBy : "",
        render: (item: PodDexData) => {
          return `<div class="text-black-lp-300 uppercase">${ellipsisString(
            item.deliveredBy,
            34
          )}</div>
            <div class="capitalize text-gray-lp-500">${
              item.deliveredByType
            }</div>`;
        }
      },
      {
        name: "Pengiriman Ke",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: PodDexData) => {
          return `<div class="overflow-ellipsis text-left">${item.sttDeliveryAttempt}</div>`;
        }
      },
      {
        name: "Action",
        key: "actionable_column",
        styleHead: "w-36 text-left",
        showButton: (item: any) =>
          item.proofFile.length && item.proofFile[0] !== ""
      }
    ];
    if (!isMigrateFromElexys()) {
      columns.splice(1, 1);
    }
    return columns;
  }

  get pagination() {
    return PodDexController.podDexList.pagination;
  }

  get podDexList() {
    return PodDexController.podDexList.data;
  }

  downloadImage(items: any) {
    for (const item of items) {
      window.open(item);
    }
  }

  // custom button
  customButton = {
    outline: false,
    iconLeft: "document-download-outline-white",
    title: "Bukti",
    textColor: "white",
    color: "red-lp-100",
    small: false,
    customClass: "whitespace-no-wrap",
    disabled: false,
    clickFunction: (item: any) => {
      this.downloadImage(item.proofFile);
    }
  };

  // handle error
  get isError(): boolean {
    return PodDexController.isError;
  }
  get errorCause() {
    return PodDexController.errorCause;
  }

  isDownload = false;
}
