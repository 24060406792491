
import { Vue, Options } from "vue-class-component";
import {
  checkRolePermission,
  convertDecimalWithComma,
  ellipsisString,
  formatDateWithoutTime,
  formatPriceRP,
  formatTimeCustom,
  isMigrateFromElexys
} from "@/app/infrastructures/misc/Utils";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import ModalForm from "./component/update/update.vue";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import Notification from "@/app/ui/components/notification/index.vue";
import { Pagination } from "@/domain/entities/Pagination";
import { FlagsPermissionPodDex } from "@/feature-flags/flags-pod-dex";

@Options({
  components: {
    ModalForm,
    EmptyState,
    Notification
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    }
  }
})
export default class StationTransitInDest extends Vue {
  mounted() {
    PodDexController.setType("DEL-DEX");
    this.refs = this.$refs;
  }
  unmounted() {
    PodDexController.onReset();
  }

  // update list
  isUpdateNotification = false;

  refs: any = "";
  fetchPodDexList() {
    PodDexController.fetchPodDexList();
  }

  get isFirstRequest() {
    return PodDexController.isFirstRequest;
  }

  // loading
  get isLoading() {
    return PodDexController.isLoading;
  }

  // filter date
  get startDate() {
    return PodDexController.filter.startDate;
  }

  get endDate() {
    return PodDexController.filter.endDate;
  }

  setDateRange(value: Array<any>) {
    PodDexController.setStartDate(value[0]);
    PodDexController.setEndDate(value[1]);
    value[0] && value[1] && this.fetchPodDexList();
  }

  // filter search
  get searchValue() {
    return PodDexController.filter.search;
  }

  onSearch(value: string) {
    PodDexController.setSearch(value);
    PodDexController.setPagination(new Pagination(1, 10));
    this.fetchPodDexList();
  }

  clearSearch() {
    PodDexController.setSearch("");
    this.fetchPodDexList();
  }

  // Table
  get columns() {
    const columns = [
      {
        name: "No.",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: any, index: number) => {
          return `<div class="overflow-ellipsis text-left">${index + 1}</div>`;
        }
      },
      {
        name: `No. STT`,
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.sttNo}</div>`;
        }
      },
      {
        name: `No. Referensi`,
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.sttNoRefExternal ||
            "-"}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.totalPiece}</div>`;
        }
      },
      {
        name: "Berat Dikenakan Biaya",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.chargeableWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Produk",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.productName}
                  </div>
                </div>`;
        }
      },
      {
        name: "Status Terakhir",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.lastStatus}
                  </div>
                </div>`;
        }
      },
      {
        name: "Komoditas",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.commodityName}</div>`;
        }
      },
      {
        name: "Biaya COD",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${formatPriceRP(
            item.codAmount
          )}</div>`;
        }
      },
      {
        name: "Tanggal DEL",
        styleHead: "w-50 text-left",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${formatDateWithoutTime(
            item.deliveryDate
          )}</div>
            <div class="capitalize text-gray-lp-500">${formatTimeCustom({
              date: item.deliveryDate,
              isSecond: false
            })}</div>`;
        }
      },
      {
        name: "Nama Kurir",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.courierName}</div>`;
        }
      },
      {
        name: "No. Telp Kurir",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.courierPhoneNumber}</div>`;
        }
      },
      {
        name: "Dikirim Oleh",
        styleHead: "w-50 text-left",
        vtooltip: (item: any) =>
          item.deliveredBy?.length > 34 ? item.deliveredBy : "",
        render: (item: any) => {
          return `<div class="text-black-lp-300 uppercase">${ellipsisString(
            item.deliveredBy,
            34
          )}</div>
            <div class="capitalize text-gray-lp-500">${
              item.deliveredByType
            }</div>`;
        }
      },
      {
        name: "Pengiriman Ke",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.sttDeliveryAttempt}</div>`;
        }
      },
      {
        name: "Action",
        key: "actionable_column",
        styleHead: "w-56 text-left",
        showButton: (item: any) => item.lastStatus === "DEL"
      }
    ];
    if (!isMigrateFromElexys()) {
      columns.splice(1, 1);
    }
    return columns;
  }

  // custom button
  isPaid = true;
  onClosePaidStatus() {
    this.isPaid = true;
  }
  customButton = {
    outline: false,
    title: "Update POD/DEX",
    textColor: "white",
    color: "red-lp-100",
    small: false,
    customClass: "whitespace-no-wrap",
    disabled: !FlagsPermissionPodDex.pod_dex_update.isEnabled(),
    clickFunction: (item: any) => this.setUpdate(item, true)
  };

  get pagination() {
    return PodDexController.podDexList.pagination;
  }

  get podDexList() {
    return PodDexController.podDexList.data;
  }

  // Update
  isUpdate = false;
  async setUpdate(item: any, value: boolean) {
    if (!value && !PodDexController.isConfirm) {
      PodDexController.setResetPodDexDetailData();
    } else {
      await PodDexController.checkPaymentStatus({ sttNo: item.sttNo });
      PodDexController.setPodDexDetailData(item);
    }
    if (PodDexController.isPaid) {
      this.isUpdate = value;
    } else {
      this.isPaid = PodDexController.isPaid;
    }
  }
  get detailData() {
    return PodDexController.podDexDetailData;
  }

  // Sent
  sentValue = "";
  get sentList() {
    return [
      {
        value: "",
        name: "Pilih"
      },
      {
        value: "Ya",
        name: "Ya"
      },
      {
        value: "Tidak",
        name: "Tidak"
      }
    ];
  }
  sentSelect = false;
  onOpenDeliveredBySelect(value: boolean) {
    this.sentSelect = value;
  }
  // handle error
  get isError(): boolean {
    return PodDexController.isError;
  }
  get errorCause() {
    return PodDexController.errorCause;
  }
  // filter status
  statusSelect = false;
  statusData = [
    { name: "Latest Status", value: "" },
    { name: "DEL", value: "DEL" },
    { name: "DEX", value: "DEX" }
  ];
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  statusName = "";
  status = "";
  onSelectStatus(name: any, value: any) {
    PodDexController.setPagination(new Pagination(1, 10));
    PodDexController.selectStatus(value);
    this.onCloseStatusSelect();
    this.statusName = name;
  }
}
