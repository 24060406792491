
import { Vue, Options } from "vue-class-component";
import {
  convertDecimalAfterComma,
  ellipsisString,
  formatDateWithoutTime,
  formatPriceRP,
  formatTimeCustom,
  isMigrateFromElexys
} from "@/app/infrastructures/misc/Utils";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import { Pagination } from "@/domain/entities/Pagination";

@Options({
  components: {
    EmptyState
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    }
  }
})
export default class StationTransitInDest extends Vue {
  mounted() {
    this.refs = this.$refs;
    PodDexController.setType("CODREJ");
  }
  unmounted() {
    PodDexController.onReset();
  }

  refs: any = "";

  updateNotificationModel = true;

  fetchPodDexList() {
    PodDexController.fetchPodDexList();
  }

  get isFirstRequest() {
    return PodDexController.isFirstRequest;
  }

  // loading
  get isLoading() {
    return PodDexController.isLoading;
  }

  // filter date
  get startDate() {
    return PodDexController.filter.startDate;
  }

  get endDate() {
    return PodDexController.filter.endDate;
  }

  setDateRange(value: Array<any>) {
    PodDexController.setStartDate(value[0]);
    PodDexController.setEndDate(value[1]);
    value[0] && value[1] && this.fetchPodDexList();
  }

  // filter search
  get searchValue() {
    return PodDexController.filter.search;
  }

  onSearch(value: string) {
    PodDexController.setSearch(value);
    PodDexController.setPagination(new Pagination(1, 10));
    this.fetchPodDexList();
  }

  clearSearch() {
    PodDexController.setSearch("");
    this.fetchPodDexList();
  }

  // Table
  get columns() {
    const columns = [
      {
        name: "No.",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: any, index: number) => {
          return `<div class="overflow-ellipsis text-left">${index + 1}</div>`;
        }
      },
      {
        name: `No. STT`,
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.sttNo}</div>`;
        }
      },
      {
        name: `No. Referensi`,
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.sttNoRefExternal ||
            "-"}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.totalPiece}</div>`;
        }
      },
      {
        name: "Berat Dikenakan Biaya",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalAfterComma(
            item.chargeableWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Produk",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.productName}
                  </div>
                </div>`;
        }
      },
      {
        name: "Status Terakhir",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                    ${item.lastStatus}
                  </div>
                </div>`;
        }
      },
      {
        name: "Komoditas",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.commodityName}</div>`;
        }
      },
      {
        name: "Biaya COD",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${formatPriceRP(
            item.codAmount
          )}</div>`;
        }
      },
      {
        name: "Tanggal DEL",
        styleHead: "w-50 text-left",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${formatDateWithoutTime(
            item.deliveryDate
          )}</div>
            <div class="capitalize text-gray-lp-500">${formatTimeCustom({
              date: item.deliveryDate,
              isSecond: false
            })}</div>`;
        }
      },
      {
        name: "Nama Kurir",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.courierName}</div>`;
        }
      },
      {
        name: "No. Telp Kurir",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.courierPhoneNumber}</div>`;
        }
      },
      {
        name: "Dikirim Oleh",
        styleHead: "w-50 text-left",
        vtooltip: (item: any) =>
          item.deliveredBy.length > 34 ? item.deliveredBy : "",
        render: (item: any) => {
          return `<div class="text-black-lp-300 uppercase">${ellipsisString(
            item.deliveredBy,
            34
          )}</div>
            <div class="capitalize text-gray-lp-500">${
              item.deliveredByType
            }</div>`;
        }
      },
      {
        name: "Pengiriman Ke",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.sttDeliveryAttempt}</div>`;
        }
      }
    ];
    if (!isMigrateFromElexys()) {
      columns.splice(1, 1);
    }
    return columns;
  }

  get pagination() {
    return PodDexController.podDexList.pagination;
  }

  get podDexList() {
    return PodDexController.podDexList.data;
  }

  // handle error
  get isError(): boolean {
    return PodDexController.isError;
  }
  get errorCause() {
    return PodDexController.errorCause;
  }
}
